@import 'styles/core/variables.scss';
.fontloader {
  font-size: 0;
  font-family: 'Brandon-Grotesque';
}
#game-wrapper {
  margin-top: 10px;
}
:host ::ng-deep canvas {
  border-radius: 15px !important;
  width: 100% !important;
  height: auto !important;
}

.frame {
  padding-top: 75px;
  padding-bottom: 75px;
}

.fontLoader1 {
  font-size: 0;
  font-family: 'Brandon-Grotesque';
}

.fontLoader2 {
  font-size: 0;
  font-family: 'Metropolis';
}

.fontLoader3 {
  font-size: 0;
  font-family: 'Metropolis';
  font-weight: 700;
  font-style: normal;
}

.fontLoader4 {
  font-size: 0;
  font-family: 'Metropolis';
  font-style: italic;
}
