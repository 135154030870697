@import 'styles/core/variables.scss';

.about-header {
  background: rgb(44, 120, 219);
  background-image: url(/assets/img/home/footer-divider.png);
  background-size: 33% auto;
}

.about-header-text {
  font-size: 4rem;
  color: white;
  font-weight: 600;
  font-family: 'Russo One';
}

.about-video-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: white;
}

.about-video-text {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.bold-section h1 {
  font-weight: 700;
}

.about-video img {
  max-height: 40vh;
}

.about-large-team {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.about-team {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.about-large-team-grid {
  display: grid;
  column-gap: 8rem;
  row-gap: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  grid-template-columns: repeat(2, minmax(270px, 500px));
  justify-content: center;
  padding-top: 3rem;
}

.about-team-grid {
  display: grid;
  gap: 4.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  justify-content: center;
  padding-top: 3rem;
}

.article-head {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  min-height: 45px;
}

.article-img {
  max-width: 250px;
  min-height: 100px;
}

.article-description {
  text-align: center;
}

.article-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-content-grid {
  display: grid;
  column-gap: 3.5rem;
  padding-left: 8rem;
  padding-right: 8rem;
  grid-template-columns: repeat(2, minmax(425px, 200px));
  justify-content: center;
  padding-top: 3rem;
}

.article-span {
  font-size: 1.5rem;
}

.article-preview {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (max-width: 1300px) {
  .about-team-grid {
    grid-template-columns: repeat(3, minmax(270px, 400px));
    gap: 2.5rem;
  }
}

@media (max-width: 992px) {
  .about-team-grid {
    grid-template-columns: repeat(2, minmax(270px, 400px));
    gap: 2.5rem;
  }

  .article-content-grid {
    grid-template-columns: repeat(2, minmax(350px, 270px));
    padding-bottom: 3rem;
  }
}

@media (max-width: 992px) {
  .about-large-team-grid {
    grid-template-columns: repeat(1, minmax(270px, 500px));
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .about-team-grid {
    grid-template-columns: repeat(1, minmax(270px, 350px));
    gap: 2rem;
  }

  .article-content-grid {
    grid-template-columns: repeat(1, minmax(350px, 270px));
    gap: 2rem;
  }
}
