@import 'styles/core/variables.scss';
.header {
  background: rgb(44, 120, 219);
  background-image: url(/assets/img/home/footer-divider.png);
  background-size: 33% auto;
}

.header-text {
  font-size: 4rem;
  color: white;
  font-weight: 600;
  font-family: 'Russo One';
}
