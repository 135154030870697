@import 'styles/core/variables.scss';

.edu-header {
  background: rgb(44, 120, 219);
  background-image: url(/assets/img/home/footer-divider.png);
  background-size: 33% auto;
}

.edu-header-text {
  font-size: 4rem;
  color: white;
  font-weight: 600;
  font-family: 'Russo One';
}

.edu-video-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: white;
  h1 {
    font-weight: 900;
    text-transform: uppercase;
  }
}

.edu-video-text {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.proton-style {
  fill: rgba(255, 255, 255, 0.25);
}

.sci-ops-section {
  background: rgb(44, 120, 219);
  background-image: url(/assets/img/logo/proton.svg);
  background-repeat: no-repeat;
  background-size: auto 95%;
  background-position: center;
  padding: 3rem;
}

.bold-section h1 {
  font-weight: 700;
}

.edu-card-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.edu-video img {
  max-height: 40vh;
}

.sci-ops-img-fluid {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 0.5rem !important;
}

.sci-ops-vid-fluid {
  border-radius: 0.5rem !important;
}

.sci-ops-card-text-video {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-around;
  height: 100%;
}

.sci-ops-card-text {
  padding-top: 2rem;
  text-align: center;
}

.sci-ops-card-text p {
  font-size: large;
}

.sci-ops-card-text h1 {
  font-size: 2.125rem;
  font-weight: 600;
}

.edu-access-section {
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.edu-access-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.edu-access-grid {
  display: grid;
  gap: 2.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  justify-content: center;
  padding-top: 3rem;
}

@media (max-width: 992px) {
  .edu-access-grid {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    gap: 1rem;
  }
}

.access-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 0.75rem;
  width: 120px;
  height: 120px;
  color: rgba(255, 255, 255, 0.75);
  border-radius: 0.5rem !important;
}
