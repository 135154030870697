@import 'styles/core/variables.scss';

.main-nav {
  gap: 1rem;
}

.navbar-nav.main-nav {
  justify-content: space-evenly;
}

.nav-link {
  color: rgb(44, 120, 219) !important;
}

.nav-link:hover {
  color: #1b55a2 !important;
}

.nav-link:focus {
  color: #1b55a2 !important;
}

.text-link {
  font-size: larger;
  text-transform: uppercase;
}

.header {
  background: rgb(44, 120, 219);
  background-image: url(/assets/img/home/footer-divider.png);
  background-size: 33% auto;
}

.header-text {
  font-size: 4rem;
  color: white;
  font-weight: 600;
  font-family: 'Russo One';
}

.img-hover {
  :hover {
    transform: scale(1.075);
    cursor: pointer;
  }
}

.info-box {
  border: 5px solid #2d78dc;
  border-radius: 35px;
  padding: 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    font-weight: 700;
    font-size: 2.7rem;
    text-align: center;
    padding: 0rem 2rem;
    margin-bottom: 1.25rem;
  }
}
.grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(3, minmax(270px, 1fr));
}

.footer-divider {
  background-image: url(/assets/img/home/footer-divider.png);
  background-color: #276ab3;
  background-size: 55% auto;
  min-height: 125px;
  border-color: #276ab3;
  border-bottom-width: 15px;
  border-bottom-style: solid;
}

.footer-content {
  background-color: #2c78db;
  color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  padding: 3rem;
  justify-content: flex-end;
}
.footer-title-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
}

.footer-icon-container a {
  color: white;
}

.footer-icon-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  justify-content: space-between;
}
