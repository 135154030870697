@import 'styles/core/variables.scss';

.home-header {
  background: rgb(44, 120, 219);
  background-image: linear-gradient(90deg, rgb(44, 120, 219) 40%, rgba(44, 120, 219, 0.6) 47%, rgba(44, 120, 219, 0) 55%), url(/assets/img/home/header-bg.png);
  min-height: 450px;
  display: flex;
  border-bottom: 10px;
  background-size: auto 125%;
  background-repeat: no-repeat;
  background-position: right;
}

.header-content {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.pg-hr {
  height: 10px;
  border-top: 0;
}

.title-text {
  font-size: 2rem;
  font-weight: 900;
}

.gray-bg {
  background: hsl(220, 6%, 90%);
}

.power-up-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.power-up-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.home-stats-section {
  background: rgb(44, 120, 219);
  background-image: url(/assets/img/home/stats-bg-2.png);
  background-size: cover;
  background-position: center;
}

.home-stats-content {
  display: grid;
  gap: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  grid-template-columns: repeat(3, minmax(300px, 400px));
  justify-content: center;
  padding-top: 3rem;
}

@media (max-width: 1200px) {
  .home-stats-content {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    gap: 1rem;
  }
}

@media (max-width: 992px) {
  .home-stats-content {
    grid-template-columns: repeat(1, minmax(100px, 400px));
    gap: 1rem;
  }
}

@media (max-width: 768px) {
}

.home-stats-item {
  justify-self: center;
  font-size: 1.25rem;
  padding: 1rem;
}

.home-stats-img img {
  max-width: 150px;
}
.home-stats-img {
  display: flex;
  align-content: center;
  justify-content: center;
  min-height: 200px;
}

.awards-section {
  padding-bottom: 3rem;
  padding-top: 1rem;
}

.awards-content {
  display: grid;
  column-gap: 1rem;
  padding-left: 9rem;
  padding-right: 9rem;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  justify-content: center;
  padding-top: 3rem;
}

@media (max-width: 992px) {
  .awards-content {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    gap: 1rem;
  }
}

.awards-item {
  justify-self: center;
}

.awards-text {
  font-size: larger;
}

.awards-img img {
  max-width: 100px;
  min-height: 140px;
}
